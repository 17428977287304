import React from 'react'
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom'
import './App.css'
import Home from './Components/Home/Home'
import Header from './Components/Header/Header'
import Login from './Components/Login/Login'
import isAuthenticated from './Auth/AuthenticateUser'
import ViewArticles from './Components/ViewArticles/ViewArticles'
import EditArticle from './Components/EditArticle/EditArticle'
import AddArticle from './Components/AddArticle/AddArticle'
import EditArticleV2 from './Components/EditArticleV2/EditArticleV2'
import EditArticleV3 from './Components/EditArticleV3/EditArticleV3'
import ViewArticle from './Components/ViewArticle/ViewArticle'

function App() {
    const PrivateWrapper = () => {
        return isAuthenticated() ? <Outlet /> : <Navigate to='/login' />
    }
    return (
        <BrowserRouter>
            <div>
                <Header />
                <Routes>
                    <Route exact path='/login' element={<Login />} />
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/' element={<Home />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/add-article' element={<AddArticle />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/articles' element={<ViewArticles />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/article' element={<EditArticle />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/view-article/:id' element={<ViewArticle />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/article-v2' element={<EditArticleV2 />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/article-v3' element={<EditArticleV3 />} />
                    </Route>
                </Routes>
            </div>
        </BrowserRouter>
    )
}

export default App
