import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../../Api/Constants';
import { Container, Typography, CircularProgress, Box, Card, CardContent, CardMedia, List, ListItem, Table, TableBody, TableCell, TableRow, TableContainer, Paper } from '@mui/material';

const ViewArticle = () => {
    const { id } = useParams();
    const [articleData, setArticleData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/admin/v2/article/${id}`);
                setArticleData(response.data.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    if (loading) {
        return (
            <Container>
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    if (!articleData) {
        return (
            <Container>
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                    <Typography variant="h6">No data available</Typography>
                </Box>
            </Container>
        );
    }

    const { article, content, info } = articleData;

    return (
        <Container sx={{ mt: 8, mb: 24 }}>
            <Typography variant="h3" component="h1">{article.name}</Typography>

            {content.map((item, index) => {
                switch (item.type) {
                    case 'checklist':
                        return (
                            <Card key={index} sx={{ marginTop: 2 }}>
                                <CardContent>
                                    <List>
                                        {item.content.map((listItem, i) => (
                                            <ListItem key={i}>⚫ {listItem}</ListItem>
                                        ))}
                                    </List>
                                </CardContent>
                            </Card>
                        );
                    case 'para':
                        return (
                            <Card key={index} sx={{ marginTop: 2 }}>
                                <CardContent>
                                    <Typography variant="body1">{item.content}</Typography>
                                </CardContent>
                            </Card>
                        );
                    case 'image':
                        return (
                            <Card key={index} sx={{ marginTop: 2 }}>
                                <CardMedia component="img" image={item.content} alt="Article content" />
                            </Card>
                        );
                    case 'title':
                        return (
                            <Card key={index} sx={{ marginTop: 2 }}>
                                <CardContent>
                                    <Typography variant="h5">{item.content}</Typography>
                                </CardContent>
                            </Card>
                        );
                    case 'table':
                        return (
                            <TableContainer component={Paper} sx={{ marginTop: 2 }} key={index}>
                                <Table>
                                    <TableBody>
                                        {item.content.map((row, rowIndex) => (
                                            <TableRow key={rowIndex}>
                                                {row.map((cell, cellIndex) => (
                                                    <TableCell key={cellIndex}>{cell}</TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        );
                    default:
                        return null;
                }
            })}
        </Container>
    );
};

export default ViewArticle;
