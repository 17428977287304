import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Autocomplete, Box, Button, TextField, Typography, Grid } from '@mui/material';
import DrawerNav from '../DrawerNav/DrawerNav';
import { BASE_URL } from '../../Api/Constants';
import axios from 'axios';

export default function EditArticleV2() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const articleId = queryParams.get('id');
    const [articleData, setArticleData] = useState({ name: '' });
    const [type, setType] = useState('');
    const [content, setContent] = useState('');
    const [contentArray, setContentArray] = useState(['']);
    const [table, setTable] = useState({ rows: 5, cols: 3, data: Array.from({ length: 5 }, () => Array(3).fill('')) });
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch the article data if needed
    }, [articleId]);

    const handleAddContent = async () => {
        let requestBody;

        if (type === 'table') {
            requestBody = {
                type,
                content: table.data
            };
        } else {
            requestBody = {
                type,
                content: type === 'checklist' ? contentArray : content
            };
        }

        try {
            const response = await axios.put(`${BASE_URL}/api/admin/v1/article-content/${articleId}`, requestBody);
            if (response.status === 200) {
                setType('');
                setContent('');
                setContentArray(['']);
                setTable({ rows: 5, cols: 3, data: Array.from({ length: 5 }, () => Array(3).fill('')) });
                navigate(-1);
            } else {
                console.error('Failed to add content');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleAddItemToList = () => {
        setContentArray([...contentArray, '']);
    };

    const handleListItemChange = (index, event) => {
        const newArray = [...contentArray];
        newArray[index] = event.target.value;
        setContentArray(newArray);
    };

    const handleTableChange = (row, col, event) => {
        const newData = [...table.data];
        newData[row][col] = event.target.value;
        setTable({ ...table, data: newData });
    };

    const handleTableSizeChange = (key, value) => {
        let newData = [...table.data];

        if (key === 'rows') {
            newData = Array.from({ length: value }, (_, rowIndex) =>
                newData[rowIndex] ? newData[rowIndex] : Array.from({ length: table.cols }, () => '')
            );
        } else if (key === 'cols') {
            newData = newData.map(row =>
                Array.from({ length: value }, (_, colIndex) => row[colIndex] ? row[colIndex] : '')
            );
        }

        setTable({ ...table, [key]: value, data: newData });
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box p={2}>
                    <Typography variant='h4' sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                        Add Content {articleData.name && <>- {articleData.name}</>}
                    </Typography>
                    <Autocomplete
                        options={['para', 'image', 'heading', 'title', 'checklist', 'table']}
                        value={type}
                        onChange={(event, newValue) => {
                            setType(newValue);
                            if (newValue === 'checklist') {
                                setContentArray(['']);
                            } else if (newValue === 'table') {
                                setTable({ rows: 5, cols: 3, data: Array.from({ length: 5 }, () => Array(3).fill('')) });
                            } else {
                                setContent('');
                            }
                        }}
                        renderInput={(params) => <TextField {...params} label="Type" margin="normal" fullWidth />}
                    />

                    {type === 'checklist' ? (
                        <Box>
                            {contentArray.map((item, index) => (
                                <TextField
                                    key={index}
                                    label={`Item ${index + 1}`}
                                    value={item}
                                    onChange={(event) => handleListItemChange(index, event)}
                                    fullWidth
                                    margin="normal"
                                />
                            ))}
                            <Button variant="outlined" onClick={handleAddItemToList} sx={{ mt: 2 }}>
                                Add Item
                            </Button>
                        </Box>
                    ) : type === 'table' ? (
                        <Box>
                            <TextField
                                label="Rows"
                                type="number"
                                value={table.rows}
                                onChange={(e) => handleTableSizeChange('rows', Number(e.target.value))}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="Columns"
                                type="number"
                                value={table.cols}
                                onChange={(e) => handleTableSizeChange('cols', Number(e.target.value))}
                                fullWidth
                                margin="normal"
                            />
                            <Grid container spacing={2} sx={{ mt: 2 }}>
                                {table.data.map((row, rowIndex) => (
                                    <Grid item xs={12} key={rowIndex}>
                                        <Grid container spacing={1}>
                                            {row.map((cell, colIndex) => (
                                                <Grid item xs={4} key={colIndex}>
                                                    <TextField
                                                        label={`Row ${rowIndex + 1} Col ${colIndex + 1}`}
                                                        value={cell}
                                                        onChange={(event) => handleTableChange(rowIndex, colIndex, event)}
                                                        fullWidth
                                                        margin="normal"
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    ) : (
                        <TextField
                            label="Content"
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                    )}
                    <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleAddContent}>
                        Add Content
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
