import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'
import DrawerNav from '../DrawerNav/DrawerNav'
import { BASE_URL } from '../../Api/Constants'

import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors, closestCorners } from '@dnd-kit/core'
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import { Column } from '../DNDComponents/Column/Column'

export default function EditArticle() {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const articleId = queryParams.get('id')
    const [articleData, setArticleData] = useState({ name: '' })
    const [articleContent, setArticleContent] = useState([])

    const [tasks, setTasks] = useState([])

    const handleArticleContent = (article_content) => {
        let temp_tasks = []
        article_content.forEach((eachPart, index) => {
            temp_tasks.push({
                id: index + 1,
                title: eachPart.content,
                type: eachPart.type,
                content: eachPart.content
            })
        })
        setArticleContent(article_content)
        setTasks(temp_tasks)
    }

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    )

    const getTaskPos = (id) => tasks.findIndex((task) => task.id === id)

    const handleDragEnd = (event) => {
        const { active, over } = event

        if (active.id === over.id) return

        setTasks((tasks) => {
            const originalPos = getTaskPos(active.id)
            const newPos = getTaskPos(over.id)

            return arrayMove(tasks, originalPos, newPos)
        })
    }

    const handleSubmit = async () => {
        const reorderedContent = tasks.map(task => ({
            type: task.type,
            content: task.content
        }))

        try {
            const response = await fetch(`${BASE_URL}/api/admin/v2/article-content/${articleId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ content: reorderedContent })
            })

            const data = await response.json()

            if (response.ok) {
                console.log('Article updated successfully:', data)
            } else {
                console.error('Error updating article:', data.message)
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    useEffect(() => {
        const fetchArticleData = async () => {
            try {
                const response = await fetch(`${BASE_URL}/api/admin/v2/article/${articleId}`)
                if (response.ok) {
                    const data = await response.json()
                    setArticleData(data.data.articleData)
                    const { content } = data.data.articleData
                    handleArticleContent(content)
                } else {
                    console.error('Failed to fetch article data')
                }
            } catch (error) {
                console.error('Error:', error)
            }
        }

        fetchArticleData()
    }, [articleId])

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box p={2}>
                    <Typography variant='h4' sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                        Rearrange Article {articleData.name && <>- {articleData.name}</>}
                    </Typography>
                    <DndContext sensors={sensors} collisionDetection={closestCorners} onDragEnd={handleDragEnd}>
                        <Column id='toDo' tasks={tasks} />
                    </DndContext>
                    <Box mt={2}>
                        <Button variant='contained' color='primary' onClick={handleSubmit}>
                            Save Changes
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
