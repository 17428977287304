import React, { useState, useEffect } from 'react';
import {
    Box, Typography, IconButton, Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, Tooltip, Button
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon, Search as SearchIcon, PanoramaFishEye, ViewAgenda } from '@mui/icons-material';
import DrawerNav from '../DrawerNav/DrawerNav';
import { BASE_URL } from '../../Api/Constants';
import { useNavigate } from 'react-router-dom';
import EditArticleDetailsModal from './EditArticleDetailsModal';
import { showTimeInAgoFormat } from '../../Utils/Time';

export default function ViewArticles() {
    const navigate = useNavigate();
    const [examsList, setExamsList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [articleToDelete, setArticleToDelete] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    const fetchArticles = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/user/v1/articles`);
            if (response.ok) {
                const data = await response.json();
                setExamsList(data.data.list);
            } else {
                console.error('Failed to fetch articles');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchArticles();
    }, []);

    const handleEditContent = (screenId) => {
        navigate('/article-v3?id=' + screenId);
    };

    const handleAddContent = (screenId) => {
        navigate('/article-v2?id=' + screenId);
    };

    const handleDelete = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/admin/v1/article/${articleToDelete}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                setOpenDeleteDialog(false);
                setArticleToDelete(null);
                fetchArticles();
            } else {
                console.error('Failed to delete article');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleOpenEditModal = (article) => {
        setSelectedArticle(article);
        setOpenEditModal(true);
    };

    const openArticleView = (id) => {
        navigate('/view-article/' + id)
    }

    const handleCloseEditModal = () => {
        setOpenEditModal(false);
        setSelectedArticle(null);
        fetchArticles();
    };

    const handleOpenDeleteDialog = (articleId) => {
        setArticleToDelete(articleId);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setArticleToDelete(null);
    };

    const filteredArticles = examsList.filter(article =>
        article.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3, color: '#524d4d' }}>
                <Box p={2}>
                    <Typography variant='h4' sx={{ fontWeight: 'bolder', mb: 4 }}>
                        STPL Articles
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                        <TextField
                            variant="outlined"
                            placeholder="Search articles"
                            onChange={(e) => setSearchQuery(e.target.value)}
                            InputProps={{
                                startAdornment: <SearchIcon />
                            }}
                            fullWidth
                        />
                    </Box>
                    {loading ? (
                        <Typography sx={{ my: 2 }}>Loading...</Typography>
                    ) : filteredArticles.length === 0 ? (
                        <Typography sx={{ my: 2 }}>No articles found!</Typography>
                    ) : (
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Updated</TableCell>
                                        <TableCell>Created</TableCell>
                                        <TableCell>Edit</TableCell>
                                        <TableCell>View</TableCell>
                                        <TableCell>Add Content</TableCell>
                                        <TableCell>Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredArticles.map((article) => (
                                        <TableRow key={article._id}>
                                            <TableCell>{article.name}</TableCell>
                                            <TableCell>{showTimeInAgoFormat(article.updatedAt)}</TableCell>
                                            <TableCell>{showTimeInAgoFormat(article.createdAt)}</TableCell>
                                            <TableCell>
                                                <Tooltip title="Edit Article">
                                                    <IconButton onClick={() => handleOpenEditModal(article)} color="primary">
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title="View">
                                                    <IconButton onClick={() => openArticleView(article._id)} color="primary">
                                                        <ViewAgenda />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title="Add Content">
                                                    <IconButton onClick={() => handleAddContent(article._id)} color="success">
                                                        <AddIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title="Delete Article">
                                                    <IconButton onClick={() => handleOpenDeleteDialog(article._id)} color="error">
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Box>
            </Box>
            {selectedArticle && (
                <EditArticleDetailsModal
                    open={openEditModal}
                    handleClose={handleCloseEditModal}
                    article={selectedArticle}
                />
            )}
            <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete this article?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
